import logo from './logo.svg';
import './App.css';
import {Routes,Route} from 'react-router-dom'
import LoginPage from './Component/Login/Login';
import AdminDashboard from './Component/AdminDashboard/AdminDashboard';
function App() {
  return (
    <div className="App">
      <Routes>
          <Route path='/' element={<LoginPage/>}/>
          <Route path='/dashboard' element={<AdminDashboard/>}/>
      </Routes>
    </div>
  );
}

export default App;
