import React, { useState } from 'react';
import CastleForm from '../CastleForm/CastleForm';
import CastleList from '../CastleList/CastleList';
import style from './Style.module.scss';

const AdminDashboard = () => {
  const [castles, setCastles] = useState([]);

  // Add a castle
  const addCastle = (newCastle) => {
    setCastles([...castles, newCastle]);
  };

  // Remove a castle
  const removeCastle = (index) => {
    setCastles(castles.filter((_, i) => i !== index));
  };

  // Edit a castle
  const editCastle = (updatedCastle, index) => {
    const updatedCastles = [...castles];
    updatedCastles[index] = updatedCastle;
    setCastles(updatedCastles);
  };

  return (
    <div className={style['admin-dashboard']}>
      <h2 className={style['dashboard-title']}>Admin Dashboard</h2>
      <div className={style['castle-form']}>
        <CastleForm addCastle={addCastle} />
      </div>
      <div className={style['castle-list']}>
        <CastleList 
          castles={castles} 
          removeCastle={removeCastle} 
          editCastle={editCastle} 
        />
      </div>
    </div>
  );
};

export default AdminDashboard;
