import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import style from "./Style.module.scss";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Make API request for authentication
      const response = await axios.post(
        "https://www.backe.spcref.com/auth/login",
        {
          email,
          password,
        }
      );
      localStorage.setItem("authenticated", "true");
      navigate("/dashboard"); // Redirect to dashboard after successful login
    } catch (err) {
      setError("An error occurred during login");
    }
  };

  return (
    <div className={style.loginpage}>
      <h2>Admin Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
        {error && <p className={style.error}>{error}</p>}
      </form>
    </div>
  );
};

export default LoginPage;
