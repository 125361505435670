import React, { useState, useEffect } from 'react';
import style from './Style.module.scss'; // Assuming you're using CSS Modules

const CastleList = () => {
  const [castles, setCastles] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedCastle, setEditedCastle] = useState({});

  // Fetch castles data from API
  useEffect(() => {
    const fetchCastles = async () => {
      try {
        const response = await fetch('https://www.backe.spcref.com/castles/all');
        const data = await response.json();
        setCastles(data);
      } catch (error) {
        console.error('Error fetching castles data:', error);
      }
    };

    fetchCastles();
  }, []);

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedCastle({ ...castles[index] });
  };

  const handleSaveEdit = async () => {
    try {
      const response = await fetch(`https://www.backe.spcref.com/castles/update/${editedCastle._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedCastle),
      });

      if (response.ok) {
        // Update the castle list state with editedCastle here
        const updatedCastles = castles.map((castle, index) =>
          index === editingIndex ? editedCastle : castle
        );
        setCastles(updatedCastles);
        setEditingIndex(null);
      } else {
        console.error('Failed to update castle');
      }
    } catch (error) {
      console.error('Error updating castle:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedCastle(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const removeCastle = async (id, index) => {
    try {
      const response = await fetch(`https://www.backe.spcref.com/castles/delete/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedCastles = castles.filter((_, i) => i !== index);
        setCastles(updatedCastles);
      } else {
        console.error('Failed to delete castle');
      }
    } catch (error) {
      console.error('Error deleting castle:', error);
    }
  };

  return (
    <div className={style['castle-list']}>
      <h3>Castle List</h3>
      <div className={style['castle-container']}>
        {castles.map((castle, index) => (
          <div key={castle._id} className={style['castle-item']}>
            {editingIndex === index ? (
              <div className={style['edit-form']}>
                <input
                  type="text"
                  name="name"
                  value={editedCastle.name || ''}
                  onChange={handleChange}
                  placeholder="Castle Name"
                />
                <input
                  type="text"
                  name="image"
                  value={editedCastle.image || ''}
                  onChange={handleChange}
                  placeholder="Image URL"
                />
                <input
                  type="text"
                  name="state"
                  value={editedCastle.state || ''}
                  onChange={handleChange}
                  placeholder="State"
                />
                <input
                  type="text"
                  name="migrationBracket"
                  value={editedCastle.migrationBracket || ''}
                  onChange={handleChange}
                  placeholder="Migration Bracket"
                />
                <input
                  type="text"
                  name="troopsPower"
                  value={editedCastle.troopsPower || ''}
                  onChange={handleChange}
                  placeholder="Troops Power"
                />
                <input
                  type="text"
                  name="technologyPower"
                  value={editedCastle.technologyPower || ''}
                  onChange={handleChange}
                  placeholder="Technology Power"
                />
                <input
                  type="text"
                  name="unitSpecialtyPower"
                  value={editedCastle.unitSpecialtyPower || ''}
                  onChange={handleChange}
                  placeholder="Unit Specialty Power"
                />
                <input
                  type="text"
                  name="dragonLevel"
                  value={editedCastle.dragonLevel || ''}
                  onChange={handleChange}
                  placeholder="Dragon Level"
                />
                <input
                  type="text"
                  name="dragonPower"
                  value={editedCastle.dragonPower || ''}
                  onChange={handleChange}
                  placeholder="Dragon Power"
                />
                <input
                  type="text"
                  name="rocSpec"
                  value={editedCastle.rocSpec || ''}
                  onChange={handleChange}
                  placeholder="ROC Spec"
                />
                <input
                  type="text"
                  name="loftySpec"
                  value={editedCastle.loftySpec || ''}
                  onChange={handleChange}
                  placeholder="Lofty Spec"
                />
                <input
                  type="text"
                  name="dmSets"
                  value={editedCastle.dmSets || ''}
                  onChange={handleChange}
                  placeholder="DM Sets"
                />
                <input
                  type="text"
                  name="legionSkins"
                  value={editedCastle.legionSkins || ''}
                  onChange={handleChange}
                  placeholder="Legion Skins"
                />
                <input
                  type="text"
                  name="pendants"
                  value={editedCastle.pendants || ''}
                  onChange={handleChange}
                  placeholder="Pendants"
                />
                <input
                  type="text"
                  name="castleSkins"
                  value={editedCastle.castleSkins || ''}
                  onChange={handleChange}
                  placeholder="Castle Skins"
                />
                <input
                  type="text"
                  name="wings"
                  value={editedCastle.wings || ''}
                  onChange={handleChange}
                  placeholder="Wings"
                />
                <input
                  type="text"
                  name="halo"
                  value={editedCastle.halo || ''}
                  onChange={handleChange}
                  placeholder="Halo"
                />
                <input
                  type="text"
                  name="migrationTickets"
                  value={editedCastle.migrationTickets || ''}
                  onChange={handleChange}
                  placeholder="Migration Tickets"
                />
                <input
                  type="text"
                  name="decorScore"
                  value={editedCastle.decorScore || ''}
                  onChange={handleChange}
                  placeholder="Decor Score"
                />
                <input
                  type="text"
                  name="specialBagItems"
                  value={editedCastle.specialBagItems ? editedCastle.specialBagItems.join(', ') : ''}
                  onChange={(e) => setEditedCastle(prev => ({
                    ...prev,
                    specialBagItems: e.target.value.split(',').map(item => item.trim())
                  }))}
                  placeholder="Special Bag Items (comma separated)"
                />
                <input
                  type="text"
                  name="desiredPrice"
                  value={editedCastle.desiredPrice || ''}
                  onChange={handleChange}
                  placeholder="Desired Price"
                />
                <button onClick={handleSaveEdit}>Save</button>
                <button onClick={() => setEditingIndex(null)}>Cancel</button>
              </div>
            ) : (
              <div className={style['castle-details']}>
                <img src={castle.image} alt={castle.name} className={style['castle-image']} />
                <p>State: {castle.state}</p>
                <p>Migration Bracket: {castle.migrationBracket}</p>
                <p>Troops Power: {castle.troopsPower}</p>
                <p>Technology Power: {castle.technologyPower}</p>
                <p>Unit Specialty Power: {castle.unitSpecialtyPower}</p>
                <p>Dragon Level: {castle.dragonLevel}</p>
                <p>Dragon Power: {castle.dragonPower}</p>
                <p>ROC Spec: {castle.rocSpec}</p>
                <p>Lofty Spec: {castle.loftySpec}</p>
                <p>DM Sets: {castle.dmSets}</p>
                <p>Legion Skins: {castle.legionSkins}</p>
                <p>Pendants: {castle.pendants}</p>
                <p>Castle Skins: {castle.castleSkins}</p>
                <p>Wings: {castle.wings}</p>
                <p>Halo: {castle.halo}</p>
                <p>Migration Tickets: {castle.migrationTickets}</p>
                <p>Decor Score: {castle.decorScore}</p>
                <p>Special Bag Items: {castle.specialBagItems.join(', ')}</p>
                <p>Desired Price: {castle.desiredPrice}</p>
                <button onClick={() => handleEditClick(index)}>Edit</button>
                <button onClick={() => removeCastle(castle._id, index)}>Remove</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CastleList;
