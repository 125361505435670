import React, { useState } from 'react';
import axios from 'axios'; // Import axios for making API requests
import style from './Style.module.scss'; // Assuming you're using CSS Modules

const CastleForm = ({ addCastle }) => {
  const [castle, setCastle] = useState({
    name: '',
    image: '',
    video: '', 
    state: '',
    migrationBracket: '',
    troopsPower: '',
    technologyPower: '',
    unitSpecialtyPower: '',
    dragonLevel: '',
    dragonPower: '',
    paidHeroes: 'false',
    rocSpec: '',
    loftySpec: '',
    dmSets: '',
    legionSkins: '',
    pendants: '',
    castleSkins: '',
    wings: '',
    halo: '',
    migrationTickets: '',
    decorScore: '',
    specialBagItems: [],
    desiredPrice: ''
  });
  console.log(castle);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCastle(prevCastle => ({
      ...prevCastle,
      [name]: type === 'checkbox' ? (checked ? 'true' : 'false') : value
    }));
  };

  const handleSpecialBagItemsChange = (e) => {
    const items = e.target.value.split(',').map(item => item.trim());
    setCastle(prevCastle => ({
      ...prevCastle,
      specialBagItems: items
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCastle(prevCastle => ({
          ...prevCastle,
          image: reader.result // This is the base64 string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: castle.name,
      image: castle.image,
      video: castle.video, // Include video field
      state: castle.state,
      migrationBracket: castle.migrationBracket,
      troopsPower: castle.troopsPower,
      technologyPower: castle.technologyPower,
      unitSpecialtyPower: castle.unitSpecialtyPower,
      dragonLevel: castle.dragonLevel,
      dragonPower: castle.dragonPower,
      paidHeroes: castle.paidHeroes === 'true' ? 'Yes' : 'No', // Convert to desired format
      rocSpec: castle.rocSpec,
      loftySpec: castle.loftySpec,
      dmSets: castle.dmSets,
      legionSkins: castle.legionSkins,
      pendants: castle.pendants,
      castleSkins: castle.castleSkins,
      wings: castle.wings,
      halo: castle.halo,
      migrationTickets: castle.migrationTickets,
      decorScore: castle.decorScore,
      specialBagItems: castle.specialBagItems,
      desiredPrice: castle.desiredPrice
    };

    try {
      console.log(data);
      await axios.post('https://www.backe.spcref.com/castles/create', data);
      alert('Castle added successfully!');
      addCastle(castle);
      
      // Reset form
      setCastle({
        name: '',
        image: '',
        video: '', 
        state: '',
        migrationBracket: '',
        troopsPower: '',
        technologyPower: '',
        unitSpecialtyPower: '',
        dragonLevel: '',
        dragonPower: '',
        paidHeroes: 'false',
        rocSpec: '',
        loftySpec: '',
        dmSets: '',
        legionSkins: '',
        pendants: '',
        castleSkins: '',
        wings: '',
        halo: '',
        migrationTickets: '',
        decorScore: '',
        specialBagItems: [],
        desiredPrice: ''
      });
    } catch (error) {
      console.error('Error adding castle:', error);
      alert('Failed to add castle. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={style['castle-form']}>
      <input type="text" name="name" placeholder="Castle Name" value={castle.name} onChange={handleChange} />
      <input type="file" name="image" onChange={handleImageChange} /> {/* File input for image */}
      <input type="text" name="video" placeholder="Video URL" value={castle.video} onChange={handleChange} /> {/* New video input */}
      <input type="text" name="state" placeholder="State" value={castle.state} onChange={handleChange} />
      <input type="text" name="migrationBracket" placeholder="Migration Bracket" value={castle.migrationBracket} onChange={handleChange} />
      <input type="text" name="troopsPower" placeholder="Troops Power" value={castle.troopsPower} onChange={handleChange} />
      <input type="text" name="technologyPower" placeholder="Technology Power" value={castle.technologyPower} onChange={handleChange} />
      <input type="text" name="unitSpecialtyPower" placeholder="Unit Specialty Power" value={castle.unitSpecialtyPower} onChange={handleChange} />
      <input type="text" name="dragonLevel" placeholder="Dragon Level" value={castle.dragonLevel} onChange={handleChange} />
      <input type="text" name="dragonPower" placeholder="Dragon Power" value={castle.dragonPower} onChange={handleChange} />
      <input type="text" name="paidHeroes" placeholder="Paid Heroes" value={castle.paidHeroes} onChange={handleChange} />
      <input type="text" name="rocSpec" placeholder="ROC Spec" value={castle.rocSpec} onChange={handleChange} />
      <input type="text" name="loftySpec" placeholder="Lofty Spec" value={castle.loftySpec} onChange={handleChange} />
      <input type="text" name="dmSets" placeholder="DM Sets" value={castle.dmSets} onChange={handleChange} />
      <input type="text" name="legionSkins" placeholder="Legion Skins" value={castle.legionSkins} onChange={handleChange} />
      <input type="text" name="pendants" placeholder="Pendants" value={castle.pendants} onChange={handleChange} />
      <input type="text" name="castleSkins" placeholder="Castle Skins" value={castle.castleSkins} onChange={handleChange} />
      <input type="text" name="wings" placeholder="Wings" value={castle.wings} onChange={handleChange} />
      <input type="text" name="halo" placeholder="Halo" value={castle.halo} onChange={handleChange} />
      <input type="text" name="migrationTickets" placeholder="Migration Tickets" value={castle.migrationTickets} onChange={handleChange} />
      <input type="text" name="decorScore" placeholder="Decor Score" value={castle.decorScore} onChange={handleChange} />
      <input
        type="text"
        name="specialBagItems"
        placeholder="Special Bag Items (comma separated)"
        value={castle.specialBagItems.join(', ')}
        onChange={handleSpecialBagItemsChange}
      />
      <input type="text" name="desiredPrice" placeholder="Desired Price" value={castle.desiredPrice} onChange={handleChange} />
      <div>
        <button type="submit">Add Castle</button>
      </div>
    </form>
  );
};

export default CastleForm;
